import axios, {post} from 'axios';
import qs, {stringify} from 'qs';
import {root_elem} from "../const";
import dateFnsFormat from "date-fns/format";

const emptyFn = ()=>{};

export const request = async (url='',request_body={}, effect= emptyFn) => {
    try{

        let result = await post(url,stringify(request_body));
            return result.data;

    }
    catch(e) {

        return {error:'Ошибка запроса'};
    }
};

export function setStorage(key,data) {
    sessionStorage.setItem(`eurotrans_form[${key}]`,JSON.stringify(data))
}
export function getStorage(key,defData){
    return sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : defData
}
export function renderInPage(){
    return root_elem.dataset.place == 'main' ? true : false
}
export function create_url(state) {
    //debugger;
   let {selected_link, selected_date,selected_pessangers: {adults,children},selected_age} = state;
   let url={
        [window.bf_form.tours.date_url_param]: selected_date.length > 0 ? selected_date : dateFnsFormat(new Date, 'dd.MM.yyyy'),
        [window.bf_form.tours.adults_url_param]: adults,
        [window.bf_form.tours.children_url_param]: children,
    }

    url = qs.stringify(url);

    Object.values(selected_age).forEach((item,index)=>{
        url += `&${window.bf_form.tours.children_age_url_param}=${item}`
    });


    selected_link = `${selected_link}${url}`;
    window.location.href = selected_link;

}

export function revers(key1,key2,data){
    let buf = data[key1];
    data[key1]=data[key2];
    data[key2]= buf;
    return {...data};
}
export function updateKeyName(str,obj){
    let buf ={};
    for(let key in obj){
        buf[`${str}[${key}]`] = obj[key];
    }
    return buf;
}
export async function getDataUrl(url,body,callback){
    try{
        let result = await axios.post(url,qs.stringify(body));
        return result.data;
    }
    catch(error){
        console.log(error);
    }
}
export async function getDataUrlMiddleware(url,body,middleWare,callback){
    try{
        let result = await axios.post(url,qs.stringify(body));
        return callback(middleWare(result.data));
    }
    catch(error){
        console.log(error);
    }
}
export function disable(data1,data2) {
    return  (data1.length > 0 && data2.length > 0) && true
}
export function regReplace(data,callback) {
    return data.map(item=>{
        let re = /([^\)]+)\((.*)\)/;
        let point = item.name.match(re);
        if(point !== null){
            item.point = point[2];
            item.shortName = point[1];
        }
        else {
            item.point = null;
            item.shortName = null;
        }
        return item;
    });
}
export function getCoords(elem) {
    let box = elem.getBoundingClientRect();

    return {
        top: box.top + window.pageYOffset,
        left: box.left + window.pageXOffset
    };
}
//http://site.ecotour.by/tours/armeniya-gruziya/?sessid=0da7755f07215b6d48de2ca26aef4c5c&s2o=Y&searchUrl=%2Favtobusnye-tury%2F&arFilterAutobas_51_1980578084=Y&travelbooking%5Bdate_from%5D=04.11.2019&travelbooking%5Badults%5D=2&travelbooking%5Bchildren%5D=2&travelbooking%5Bchildren_age%5D%5B%5D=3&travelbooking%5Bchildren_age%5D%5B%5D=1