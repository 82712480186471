import React, {useEffect, useState} from 'react';
import DayPicker from 'react-day-picker/DayPicker';
import {DateUtils} from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import * as locale from './locale';
import './style.css';
import dateFnsFormat from 'date-fns/format';
import dateFnsCompare from 'date-fns/compareAsc';
import addDays from 'date-fns/addDays';
import dateFnsParse from 'date-fns/parse';
import {mapValues,difference,uniq} from 'lodash'

function renderInPage() {
    return document.querySelector('#root').dataset.showprice == 1
}

function parseDate(str, format, locale) {
    const parsed = dateFnsParse(str, format, {locale});
    if (DateUtils.isDate(parsed)) {
        return parsed;
    }
    return undefined;
}

function formatDate(date, format, locale) {
    return dateFnsFormat(date, format, {locale});
}

function toDateArray(date = []) {

    let result = [];
    date.forEach(key => {
        date.forEach(item => result.push({
            ...item,
            date: dateFnsParse(item.date, 'dd.MM.yyyy', new Date()),

        }));
    })
    return result;
}

const renderDay = (data) => day => {

    let item = {
        price: {val: '', cur: ''}
    };

    item = {...data.find(item => formatDate(item.date, 'yyyy-MM-dd').toString() == formatDate(day, 'yyyy-MM-dd').toString())};

    return <div>
        <div>
            {day.getDate()}
        </div>
        <div className={'DayPicker_price'}>
            {renderInPage() && (
                <>
                    <span>{item.price && item.price.val.toString()}</span>
                    <small>{item.price && item.price.cur}</small>
                </>
            )
            }

        </div>
    </div>
}


const CustomDatePicker = (props) => {
    const {
        initData,
        minDate = new Date(),
        callback = () => {
        },
        setOpen = () => {
        },
        defData = new Date(),
    } = props;
    const [dates, setDates] = useState(initData);
    const FORMAT = 'dd.MM.yyyy';
    const [selectDay, setSelectDay] = useState(dateFnsParse(defData, FORMAT, new Date()));

    useEffect(()=>{
        setDates(initData);
    },[initData])
    // useEffect(() => {
    //         setSelectDay(dateFnsParse(defData, FORMAT, new Date()))
    //     }, [defData]
    // );
    //console.log(toDateArray(initData));

    const modifires = {
        sunday: {daysOfWeek: [0, 6]},
        hilight: toDateArray(initData)
    };

    function handleSelectDay(day, {disabled}, e) {
        if (disabled == undefined) {
            setSelectDay(day);
            setOpen(false);
            callback(dateFnsFormat(day, FORMAT));
        }

    }
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    let maxDate = dates[dates.length-1].date;
    let allDates = dates.map(({date})=>date);

    //console.log(Object.values(dates)[0][Object.values(dates)[0].length - 1].date);
    //
    //
    //
    let calendarb=[];
    for(let i = 0; i<365; i++){
        let data = dateFnsFormat(addDays(new Date(),i),'dd.MM.yyyy');
        data.toString();
        calendarb.push(data);
    }


    let disableDate = calendarb.filter(item=>{
        if(item != allDates.find(day => day == item)){
            return item;
        }

    });


    disableDate = disableDate.map(day=> dateFnsParse(day, 'dd.MM.yyyy', new Date()));
    disableDate.push({before:minDate.length > 0 ? dateFnsParse(minDate, 'dd.MM.yyyy', new Date()) : new Date()});
    disableDate.push({after: maxDate ? dateFnsParse(maxDate, 'yyyy-MM-dd', new Date()) : addDays(new Date(), 90)})

    return (

        <DayPicker
            firstDayOfWeek={1}
            month={!isSafari && selectDay && selectDay != 'Invalid Date' ? selectDay :
                minDate.length > 0 ? dateFnsParse(minDate, 'dd.MM.yyyy', new Date()) : new Date()}
            months={locale.MONTHS}
            weekdaysLong={locale.WEEKDAYS_SHORT}
            weekdaysShort={locale.WEEKDAYS_SHORT}
            disabledDays={
                disableDate
            }
            modifiers={modifires}
            formatDate={formatDate}
            format={FORMAT}
            parseDate={parseDate}
            selectedDays={selectDay}
            onDayClick={handleSelectDay}
            //renderDay={renderDay(toDateArray(initData))}
        />

    );
};

export default CustomDatePicker;