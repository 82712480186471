import React, {useEffect, useState} from 'react';
//components
import DatePicker from './container/DayPickerPopper';
import Counter from './container/counterPessangers';
import AutoCompleate from "./container/autoCompleat";
import Spiner from '@material-ui/core/CircularProgress';
//utiles
import dateFnsFormat from 'date-fns/format';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import * as API from './api/index.js';
import {uniqBy, unionBy, uniqWith, isEqual, sortBy, concat} from 'lodash';
import compareAsc from 'date-fns/compareAsc';
import dateFnsParse from 'date-fns/parse';
import axios from 'axios';
import qs from 'qs';

//css
import './response.css';
import './style.scss';
//demo data
import demoData from './demoData/demo';
import demoDates from './demoData/dates';

//const
import {root_elem} from "./const";

//

let prevVal = 0;

function App() {
    const [data, setData] = useState({
        direction: [],
        tours: {},
        dates: []
    });
    const [request_body, setBody] = useState({
        selected_direction: 'all',
        selected_tour: 'all',
        selected_date: '',
        selected_link:window.bf_form.tours.default_url || '/',
        selected_pessangers: {
            adults: 1,
            children: 0
        },
        selected_age: {}

    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const sm = useMediaQuery(theme.breakpoints.down('sm'));

    //methods
    const getDataDirectionAndTour = async () => {


            let result = await API.request(window.bf_form.tours.url_direction,{sessid:window.bf_form.tours.sessid});
            if (result.error == true || result.error == 'Ошибка запроса') {
                setData({direction: demoData.direction, tours: demoData.tours, dates: []});
            } else {

                setData({direction: result.direction, tours: result.tours, dates: []});
            }


    };
    const getDates = async (tours_id = []) => {
        let result = await API.request(window.bf_form.tours.date_url, {sessid:window.bf_form.tours.sessid,tours_id:tours_id});

        if (result.error == true || result.error == 'Ошибка запроса') {
            setError(result.error);
            if (data.direction.length > 0) {
                let dates = demoDates.dates.filter(item => item.tour_id == tours_id.find(id => id == item.tour_id));
                dates = unionBy(sortBy(dates, ['date','price']), 'date');
                setTimeout(()=>{
                    setData(
                        {
                            ...data,
                            dates: dates
                        });
                },1000)
            }
        } else {
            console.log(result,'-result');
            if (result.dates.length > 0) {
                console.log(tours_id,'-tours-id',);
                let dates = result.dates.filter(item => item.tour_id == tours_id.find(id => id == item.tour_id));
                dates = unionBy(sortBy(dates, [ 'date','price']), 'date');

                setData(
                    {
                        ...data,
                        dates: dates
                    });
            }
            //setData({...data, dates: result.dates})
        }
    };

    const getAllTours_id  = () =>{
        if (data.direction.length > 0) {
            let tours_id = data.direction.map(({tours_id}) => tours_id);
            tours_id = tours_id.reduce((prev, cur, index) => {
                return prev.concat(cur);
            });
            return tours_id;
        }
    }

    //effects
    useEffect(() => {
        getDataDirectionAndTour();
    }, []);

    useEffect(() => {
        if (data.direction.length > 0) {
            let tours_id = data.direction.map(({tours_id}) => tours_id);
            tours_id = tours_id.reduce((prev, cur, index) => {
                return prev.concat(cur);
            });
            if(tours_id && tours_id.length > 0){
                getDates(tours_id);
            }
        }
    }, [data.direction]);

    useEffect(()=>{
        console.log(1);
        if(data.dates.length > 0){
            let dateArr = data.dates.map(({date})=>dateFnsParse(date,'dd.MM.yyyy',new Date())).sort(compareAsc);

            setBody({...request_body, selected_date: dateFnsFormat(dateArr[0],'dd.MM.yyyy')})

        }else{
            console.log(3);
        }

    },[data.dates]);


    useEffect(() => {
            let direction = data.direction.find(item => request_body.selected_direction == item.id);
            if (direction != null && direction != undefined) {
                request_body.selected_link = direction.link;
                setBody({...request_body});
                getDates(direction.tours_id);
            }
            if(request_body.selected_direction == 'all'){
                let all_tour_id = data.direction.map(({tours_id}) => tours_id);
                if(all_tour_id.length >0){
                    all_tour_id = all_tour_id.reduce((prev, cur, index) => {
                        return prev.concat(cur);
                    });
                    request_body.selected_link = window.bf_form.tours.default_url;
                    setBody({...request_body});
                    getDates(all_tour_id);
                }
            }
        }
        , [request_body.selected_direction]);


    useEffect(() => {
        let {selected_tour, selected_direction} = request_body;
        if (selected_tour != null && selected_tour != undefined && selected_tour != 'all') {
            getDates([selected_tour]);
            request_body.selected_link = find_tour().find(item=>item.id == selected_tour).link;
            setBody({...request_body});
        }
        else if( request_body.selected_tour == 'all'){
            if(selected_direction == 'all'){
                const tours_id = getAllTours_id();

                if(tours_id !=undefined){
                    if(tours_id.length > 0){
                        getDates(tours_id);
                        request_body.selected_link = window.bf_form.tours.default_url;
                        setBody({...request_body});
                    }

                }

            }
            else if( selected_direction != undefined && selected_direction != null){
                let direction = data.direction.find(item => request_body.selected_direction == item.id);
                if(direction != null && direction != undefined) {
                    request_body.selected_link = direction.link;
                    setBody({...request_body});
                    getDates(direction.tours_id);
                }

            }
        }

    }, [request_body.selected_tour]);

    useEffect(()=>{
        const {children} = request_body.selected_pessangers;

        if(children > 0){
            if(prevVal < children){
                setBody({
                    ...request_body,
                    selected_age: {...request_body.selected_age,[children]:0}
                })
            }
            else{
                delete request_body.selected_age[children+1];
            }


            prevVal = children;
        }
        else{
                request_body.selected_age = {};
                setBody({
                    ...request_body,
                    selected_age: {}
                })
        }
    },[request_body.selected_pessangers.children]);

    const set_data = key => data => {
        switch (key) {
            case 'selected_direction':
                setBody({
                    ...request_body,
                    [key]: data,
                    selected_tour: 'all',
                    selected_date: ''
                });
                break;
            case 'selected_tour': {
                setBody({
                    ...request_body,
                    [key]: data,
                    selected_date: ''
                });
                break;
            }
            case 'selected_age':{
                let buf = request_body.selected_age;
                buf[Object.keys(data)] =data[Object.keys(data)];
                setBody({...request_body,selected_age: buf});
                break;
            }

            default:
                setBody({
                    ...request_body,
                    [key]: data
                });
                break;
        }
    };

    const find_tour = () => {
        if (request_body.selected_direction != undefined && request_body.selected_direction != null && request_body.selected_direction == 'all' ) {
            return Object.values(data.tours);
        } else {
            const direction = data.direction.find(item => item.id == request_body.selected_direction);
            if (direction) {
                let {tours_id} = direction;
                tours_id = tours_id.map(id => data.tours[id]);
                return tours_id;
            }
        };
    };

    const sendData = async () =>{
       //let body = qs.stringify({sessid:window.bf_form.session,...request_body});

       try {
           setLoading(true);
           await window.sleep(1000);
           API.create_url(request_body);
           setLoading(false);
       }
       catch (e) {
           setLoading(false);
       }

    };



    return (
        <div className="search_form__container">
            <div id="booking-form" className="bf_form" >
                <AutoCompleate
                    name={'arFilterAutobas_51'}
                    textLabel={window.bf_form.tours.direction.label || ''}
                    textPopper={window.bf_form.tours.direction.popper || ''}
                    initData={data.direction}
                    defData={request_body.selected_direction}
                    callback={set_data('selected_direction')}
                    textAll={'Все страны'}
                />
                <AutoCompleate
                    textLabel={window.bf_form.tours.tour.label || ''}
                    textPopper={window.bf_form.tours.tour.popper || ''}
                    initData={find_tour()}
                    defData={request_body.selected_tour}
                    callback={set_data('selected_tour')}
                    textAll={'Все туры'}
                />
                <DatePicker
                    initData={data.dates || []}
                    textLabel={window.bf_form.tours.date.label || ''}
                    textPopper={window.bf_form.tours.date.popper || ''}
                    disabled={false}
                    id={'date_from'}
                    placeholder={window.bf_form.tours.date.label}
                    callback={set_data('selected_date')}
                    defData={request_body.selected_date}
                />
                <Counter
                    textLabel={window.bf_form.tours.pessangers.label || ''}
                    textPopper={window.bf_form.tours.pessangers.popper || ''}
                    callback={set_data('selected_pessangers')}
                    callback_age = {set_data('selected_age')}
                    initData={request_body.selected_pessangers}
                    defDataAge={request_body.selected_age}
                    name={['eurotrans[adults]', 'eurotrans[children]', 'eurotrans[social]']}
                />
                <div className={`bf_form--action bf_form--action-size`}>
                    <button type={'submit'} className={`bf_form--action-btn `} onClick={sendData}>
                        {
                            loading ? <Spiner className={"bf_form--spiner"}/> : window.bf_form.tours.submit.label || 'Найти'
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}



export default App;
