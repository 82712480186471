import React, {useState,useEffect} from 'react';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import {createPortal,hydrate, render} from "react-dom";
import qs from 'qs';

window.sleep = function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
};

// window.bf_form = new Object();
// window.bf_form.tours={
//     direction:{
//         label:'Направление',
//         popper: 'Куда вы хотите поехать?'
//     },
//     tour:{
//         label:'Тур',
//         popper: 'Укажите тур'
//     },
//     date:{
//         label:'Когда',
//         popper: 'Укажите дату выезда'
//     },
//     pessangers:{
//         label:'Пассажиров',
//         popper: 'Укажите количество пассажиров',
//         description:{
//             adults:{
//                 label:'Взрослых',
//                 description:'от 12 лет'
//             },
//             children: {
//                 label:'Взрослых',
//                 description:'до 12 лет'
//             }
//         }
//     },
//     date_url_param: "travelbooking[date_from]",
//     adults_url_param: "travelbooking[adults]",
//     children_url_param: "travelbooking[children]",
//     children_age_url_param: "travelbooking[children][]",
//     url_direction :undefined,
//     date_url:'',
//     make_url:undefined,
//     sessid:undefined,
// };
function initApp(){
    const rootElement = document.getElementById("root");
    if (rootElement.hasChildNodes()) {
        hydrate(<App/>, rootElement);
    } else {
        render(<App/>, rootElement);
    }
}
document.onreadystatechange = function () {
    if (document.readyState == "complete") {
       initApp();
    }
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// axios.post('http://site.ecotour.by/local/ajax/booking/get-dates-for-highlight.php',qs.stringify({
//     //sessid: 'fd357808180be66b6150b16e08c12de2',
//     service_type: 'excursiontour',
//     object_id: 1138
// }));




serviceWorker.unregister();
