import React, {useEffect, useState, useRef} from 'react';
import ReactDOM from 'react-dom';
import './style.css';
import {Manager, Reference, Popper} from 'react-popper';
import ClickAway from '@material-ui/core/ClickAwayListener';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import nanoid from 'nanoid';

const styleIcon = {
    position: 'absolute',
    top: '1.3rem',
    right: '1.25rem'
}

const AutoCompleate = (props) => {
    //props
    const {
        initData = [],
        textAll='',
        callback = () => {
        },
        defData = '',
        textLabel='',
        textPopper='',
        name='',
        id='',
    } = props;
    //state
    const [data, setData] = useState(initData);
    const [open, setOpen] = useState(false);
    const [input, setInput] = useState(defData);
    const [indexItem,setIndexItem] = useState(0);
    const [placeholder, setPlaceholder] = useState(textPopper);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const listRef = React.createRef('');
    const inputRef = React.createRef('');
    //ref
    console.log(initData, defData);
    //effects
    useEffect(() => {
        if(initData.length > 0 ){
            setData(initData);
            let find = initData.find(item=>(item.id == defData));

            if(find){
                setInput(find.name);
            }
            else if(defData == 'all'){
                setInput(textAll);
            }
            else{
                setInput('');
            }
        }
    }, [initData]);


    useEffect(() => {

        if(defData != '' && defData != null){
            let find = initData.find(item=>(item.id == defData));

            if(find){
                console.log('2');
                setInput(find.name);
            }
            else if(defData == 'all'){
                console.log('1');
                setInput(textAll);
            }
            else{
                console.log('3');
                setInput('');
            }
        }
        else{
            setInput('')
        }
    }, [defData]);


    const handleKeyPress = e =>{
       switch (e.keyCode) {
           case 40:{
               if(indexItem <= data.length)
                setIndexItem(indexItem+1);
               break;
           }
           case 38:{
               if(indexItem > 1){
                   setIndexItem(indexItem-1);
               }
               break;
           }
           case 13:{
               setInput(data[indexItem-1].name);
               callback(data[indexItem-1].id);
               setOpen(!open);
               break;
           }
       }
    };

    // useEffect(()=>{
    //     console.log(indexItem);
    //     if(listRef.current){
    //         Array.from(listRef.current.children).forEach(item=>{
    //             item.classList.remove('hovered');
    //         });
    //         if(indexItem < listRef.current.children.length){
    //             listRef.current.children[indexItem].classList.add('hovered');
    //             listRef.current.scrollTo({top:listRef.current.children[indexItem].offsetTop - listRef.current.children[indexItem].clientHeight, behavior: "smooth"});
    //             setInput(data[indexItem-1].name);
    //             callback(data[indexItem-1].id);
    //         }
    //     }
    // },[indexItem]);

    const handleClick = (e) => {
        if(!fullScreen){
            if(e.target.closest('.bf_form-group') && data.length != 0 ){
                setOpen(!open);
                inputRef.current.focus();
//                setPlaceholder(input.length > 0 ? input : textPopper);
                setInput('')
            }
        }
        else{
            setOpen(!open);
        }

    };

    const handleChange = e => {
        const {value} = e.target;
        setOpen(true);
        setInput(value);
        let filtredData = initData.filter(({name}) => name.toLowerCase().indexOf(value.toLowerCase()) != -1);

        value.length > 0
            ? setData(filtredData)
            : (()=>{
                setData(initData);
            })();
    };

    const handleSelect = data => e => {
        if(data == 'all'){
            callback('all');
            setInput(textAll);
            setOpen(false);
        }
        else{
            callback(data.id);
            setInput(data.name);
            setOpen(false);
        }

    };

    if(!fullScreen){
        return (
            <Manager>
                <Reference>
                    {({ref}) => (
                        <div ref={ref}
                             onClick={handleClick}
                             className={`bf_form-group bf_form--size`}>
                            <label htmlFor={`search_from__${id}`} className="bf_form-control--label"
                            >
                                {textLabel}
                            </label>
                            <div className="bf_form-control--input">
                            <input autoComplete="off"
                                   className={"bf_form-control--input-item"}
                                   id={`search_from__${id}`}
                                   type="text"
                                   name={name}
                                   onChange={handleChange}
                                   onKeyDown={handleKeyPress}
                                   placeholder={placeholder}
                                   disabled={data.length > 0 && false}
                                   value={input}
                                   ref={inputRef}
                            />
                            <span className="bf_form-control--placeholder">

                            </span>
                            </div>

                        </div>
                    )}
                </Reference>
                {
                    open &&
                    ReactDOM.createPortal(
                    <Popper placement="bottom-start" style={{zIndex:1000}}>
                        {({ref, style, placement}) => (
                            <div ref={ref} style={style} data-placement={placement}>
                                <ClickAway onClickAway={()=>setOpen(false)} >
                                    <ul ref={listRef} className={'search_form__autoC_list'}>

                                        {
                                            data.length > 0 &&
                                            <>
                                                <li className='search_form__autoC_list_header'>
                                                    {textPopper}
                                                </li>
                                                <li onClick={handleSelect('all')} className={`search_form__autoC_list_item ${defData == 'all' && 'selected'}`}>
                                                    {textAll}
                                                </li>
                                                {
                                                    data.map(
                                                        (item) => <li
                                                            className={`search_form__autoC_list_item ${defData == item.id && 'selected'}`}
                                                            key={nanoid()}
                                                            id={item.id}
                                                            onClick={handleSelect(item)}>
                                                            <span className='first'>
                                                                {item.shortName == null ? item.name : item.shortName}<br/>
                                                                <small>{item.point != null ? item.point : ''}</small>
                                                            </span>
                                                            <span className="last">{item.prop}</span>
                                                        </li>)
                                                }

                                            </>
                                        }
                                    </ul>
                                </ClickAway>
                            </div>
                        )}
                    </Popper>,document.querySelector('#portal-form'))
                }
            </Manager>

        );
    }
    else{
        return <>
            <div
                 onClick={handleClick}
                 className={`bf_form-group bf_form--size`}>
                <label htmlFor={`search_from__${id}`} className="bf_form-control--label"
                >
                    {textLabel}
                </label>
                <div className="bf_form-control--input">
                <input autoComplete="off"
                       id={`search_from__${id}`}
                       type="text"
                       name={name}
                       className="bf_form-control--input-item"
                       onChange={handleChange}
                       onKeyDown={handleKeyPress}
                       placeholder={textPopper}
                       disabled={data.length > 0 && false}
                       value={input}
                       readOnly
                       ref={inputRef}
                />
                </div>
            </div>
            <Dialog open={open} fullScreen={fullScreen}>

               <div className={'bf_form-dialog'}>
                   <div className="bf_form-dialog-control--input">
                   <input autoComplete="off"
                          id={`search_from__${id}`}
                          type="text"
                          className="bf_form-dialog-control--input-item"
                          onChange={handleChange}
                          placeholder={textPopper}
                          disabled={data.length > 0 && false}
                          value={input}
                          onKeyDown={handleKeyPress}
                   />
                        <CloseIcon onClick ={()=>setOpen(!open)} style={styleIcon}/>
                   </div>
                   <ul className={'bf_form--autoC-list'}>
                       <li>
                           <span className='first'></span>
                           <span className="last"></span>
                       </li>
                       {
                           data.length > 0 &&
                           <>

                               {
                                   data.map(
                                       (item) => <li
                                           className={`bf_form--autoC-list-item ${defData == item.id && 'selected'}`}
                                           key={nanoid()}
                                           id={item.id}
                                           onClick={handleSelect(item)}>
                                           <span className='first'>{item.name}</span>
                                           <span className="last">{item.prop}</span>
                                       </li>)
                               }

                           </>
                       }
                   </ul>
               </div>
            </Dialog>
        </>
    }

};

export default AutoCompleate;

